import React from "react";
import { BasicElements } from "../types.model";
interface DisplayProps {
  size: "giga" | "mega";
  flush?: true;
  flushBottom?: true;
  flushTop?: true;
  children: BasicElements;
  color?: string;
}

export const Display = (props: DisplayProps) => {
  const classNames = [
    "display",
    `heading--${props.size}`,
    props.flush && "u-flush",
    props.flushBottom && "u-flush--bottom",
    props.flushTop && "u-flush--top",
    props.color && `text__${props.color}`
  ]
    .join(" ")
    .trim();

  return <span className={classNames}> {props.children}</span>;
};
