import React from "react";
import { BasicElements } from "../types.model";
export interface HeadingProps {
  size: 1 | 2 | 3 | 4 | 5 | 6;
  flush?: true;
  flushBottom?: true;
  flushTop?: true;
  center?: true;
  children: BasicElements;
  color?: string;
}

export const Heading = (props: HeadingProps) => {
  const Tag = `h${props.size}` as "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

  const classNames = [
    "heading",
    `heading--${Tag}`,
    props.flush && "u-flush",
    props.flushBottom && "u-flush--bottom",
    props.flushTop && "u-flush--top",
    props.center && "u-type--center",
    props.color && `text__${props.color}`
  ]
    .join(" ")
    .trim();

  return <Tag className={classNames}> {props.children}</Tag>;
};
