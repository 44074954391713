import React from "react";

interface LogoProps {
  // Color string value. eg #000000 || black
  fill?: string;
}

export const Logo = (props: LogoProps) => {
  const fill = props.fill || "#000000";

  return (
    <svg
      width="100%"
      viewBox="0 0 1034 304"
      preserveAspectRatio="xMidYMid meet"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M943.273 251.326C929.732 251.326 917.422 248.916 906.373 244.096C895.325 239.276 885.784 232.672 877.783 224.315C869.781 215.927 863.564 206.068 859.163 194.707C854.763 183.346 852.547 171.108 852.547 158.025C852.547 144.943 854.763 132.736 859.163 121.375C863.564 110.014 869.781 100.155 877.783 91.7669C885.784 83.379 895.325 76.7751 906.373 71.9551C917.422 67.1352 929.732 64.7252 943.273 64.7252C956.814 64.7252 969.125 67.1352 980.173 71.9551C991.221 76.7751 1000.76 83.379 1008.76 91.7669C1016.77 100.155 1022.98 110.014 1027.38 121.375C1031.78 132.736 1034 144.974 1034 158.025C1034 171.108 1031.81 183.314 1027.38 194.707C1022.98 206.068 1016.77 215.927 1008.76 224.315C1000.76 232.703 991.221 239.276 980.173 244.096C969.125 248.916 956.814 251.326 943.273 251.326ZM943.273 215.52C957.491 215.52 968.386 210.231 975.957 199.683C983.528 189.136 987.313 175.239 987.313 158.025C987.313 140.811 983.528 126.852 975.957 116.211C968.386 105.538 957.491 100.186 943.273 100.186C929.055 100.186 918.16 105.538 910.62 116.211C903.049 126.884 899.264 140.811 899.264 158.025C899.264 175.239 903.049 189.136 910.62 199.683C918.16 210.231 929.055 215.52 943.273 215.52Z"
        fill={fill}
      />
      <path d="M796.258 69.545H752.003V246.161H796.258V69.545Z" fill={fill} />
      <path
        d="M840.021 67.4789C829.865 67.4789 821.525 70.0141 814.97 75.0531C810.446 78.5272 806.291 83.2533 802.413 89.106V134.363C804.814 128.448 808.168 123.565 812.6 119.81C822.294 111.547 834.728 108.229 849.839 109.825H850.854V68.8561C848.361 67.9484 844.761 67.4789 840.021 67.4789Z"
        fill={fill}
      />
      <path
        d="M616.775 246.098V0H570.888V246.161H616.928V246.098H616.775Z"
        fill={fill}
      />
      <path
        d="M723.905 104.317C720.981 96.2735 716.919 89.3252 711.717 83.4725C706.516 77.6197 700.269 73.0189 692.944 69.7012C685.62 66.3836 677.433 64.6935 668.385 64.6935C656.414 64.6935 646.596 66.9783 638.933 71.5791C633.24 74.9906 627.885 79.748 622.93 85.8198V121.938C624.099 119.998 625.392 118.182 626.9 116.492C633.547 109.043 641.98 105.287 652.136 105.287C661.83 105.287 669.278 108.261 674.479 114.239C679.68 120.217 682.265 128.01 682.265 137.65V246.098H728.306V130.827C728.306 121.187 726.828 112.361 723.905 104.317Z"
        fill={fill}
      />
      <path
        d="M511.307 183.846C509.737 193.955 505.89 201.467 499.796 206.412C493.703 211.357 485.917 213.83 476.438 213.83C462.219 213.83 451.786 208.728 445.139 198.494C438.491 188.29 435.137 174.801 435.137 158.025C435.137 140.811 438.645 127.227 445.631 117.243C452.617 107.259 463.112 102.251 477.115 102.251C485.701 102.251 492.749 104.849 498.288 110.013C503.828 115.178 507.367 121.656 508.937 129.45H553.962C552.392 119.81 549.499 111.046 545.344 103.096C541.159 95.178 535.742 88.4176 529.095 82.7839C522.447 77.1502 514.6 72.7372 505.551 69.5134C496.534 66.2897 486.378 64.6935 475.084 64.6935C461.758 64.6935 449.817 67.1035 439.199 71.9234C428.582 76.7433 419.503 83.3473 411.963 91.7352C411.255 92.5177 410.67 93.3627 409.993 94.1765L388.45 158.87C388.543 171.64 390.604 183.596 394.698 194.706C398.883 206.068 404.792 215.927 412.455 224.315C420.118 232.703 429.443 239.275 440.399 244.095C451.356 248.915 463.697 251.325 477.453 251.325C488.963 251.325 499.396 249.541 508.752 245.973C518.108 242.405 526.14 237.585 532.788 231.513C539.435 225.441 544.76 218.305 548.699 210.168C552.638 202.03 554.977 193.235 555.654 183.815H511.307V183.846Z"
        fill={fill}
      />
      <path
        d="M263.101 267.162H279.35C288.614 267.162 295.2 264.971 299.17 260.621C303.109 256.27 305.079 250.418 305.079 243.063C305.079 240.778 304.863 238.18 304.402 235.332C303.94 232.452 303.201 229.197 302.186 225.504C301.17 221.842 299.816 217.523 298.123 212.609C296.431 207.664 294.338 201.874 291.845 195.239L245.159 69.545H293.569L319.297 149.074C320.651 153.205 321.944 157.493 323.205 161.969C324.436 166.444 325.637 170.638 326.745 174.551C327.883 178.901 329.114 183.283 330.469 187.633H331.146C332.038 183.283 333.054 178.901 334.192 174.551C335.085 170.638 336.162 166.476 337.393 161.969C338.624 157.493 339.947 153.174 341.302 149.074L365.676 69.545H412.055L351.457 250.637C348.072 260.496 344.625 268.821 341.148 275.582C337.639 282.342 333.577 287.85 328.961 292.107C324.344 296.364 318.928 299.399 312.711 301.246C306.494 303.093 298.985 304 290.214 304H263.131V267.162H263.101Z"
        fill={fill}
      />
      <path
        d="M239.004 81.4381C229.063 70.2959 214.968 64.7248 196.687 64.7248C185.639 64.7248 175.637 67.4791 166.742 72.9875C157.818 78.496 150.431 86.0702 144.553 95.7101H143.876C139.598 86.2893 133.259 78.7777 124.918 73.144C116.578 67.5104 106.853 64.6935 95.8046 64.6935C83.6175 64.6935 73.3384 67.4478 64.9982 72.9563C59.1816 76.7746 54.319 81.2816 50.2567 86.3206V122.345C51.4261 119.841 52.8418 117.525 54.5037 115.459C60.1356 108.448 67.7064 104.974 77.1853 104.974C85.7717 104.974 92.3577 107.51 97.0049 112.549C101.621 117.588 103.929 125.162 103.929 135.271V246.13H149.97V143.565C149.97 131.86 152.74 122.501 158.279 115.491C163.819 108.48 171.42 105.006 181.115 105.006C189.701 105.006 196.287 107.541 200.934 112.58C205.551 117.619 207.859 125.193 207.859 135.302V246.161H253.899V127.384C253.899 107.885 248.944 92.549 239.004 81.4381Z"
        fill={fill}
      />
      <path
        d="M44.1015 246.099V69.545H0V246.161H46.0404V246.099H44.1015Z"
        fill={fill}
      />
    </svg>
  );
};
