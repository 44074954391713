import React from "react";
import { IconProps } from "./Icon";

export const ArrowRight = (props: IconProps) => {
  const className = [
    "icon",
    props.size && `icon--${props.size}`,
    props.className
  ]
    .join(" ")
    .trim();

  return (
    <svg className={className} viewBox="0 0 22 22">
      <path
        fill="currentColor"
        d="M10.2656 0.781249L9.32813 1.67187C9.09375 1.90625 9.09375 2.28125 9.32813 2.46875L16.5938 9.78125L0.562501 9.78125C0.281251 9.78125 1.13513e-06 10.0156 1.10644e-06 10.3437L9.91702e-07 11.6562C9.67114e-07 11.9375 0.281251 12.2187 0.562501 12.2187L16.5938 12.2187L9.32813 19.4844C9.09375 19.6719 9.09375 20.0469 9.32813 20.2812L10.2656 21.1719C10.4531 21.4062 10.8281 21.4062 11.0625 21.1719L20.8594 11.375C21.0938 11.1406 21.0938 10.8125 20.8594 10.5781L11.0625 0.781249C10.8281 0.546874 10.4531 0.546874 10.2656 0.781249Z"
      />
    </svg>
  );
};
