import React, { ReactNode } from "react";

interface ButtonProps {
  element: "a" | "button";
  href?: string;
  target?: "blank" | "self" | "parent";
  type: "text" | "contained" | "outline";
  size?: "large" | "compact";
  children: ReactNode;
  onClick?: (_: any) => void;
}

export const Button = (props: ButtonProps) => {
  const classNames = [
    "button",
    `button--${props.type}`,
    props.size && `button--${props.size}`
  ]
    .join(" ")
    .trim();

  switch (props.element) {
    case "a":
      return (
        <a
          href={(props.href && props.href) || null}
          target={(props.target && `_${props.target}`) || null}
          className={classNames}
          onClick={(props.onClick && props.onClick) || null}
        >
          {props.children}
        </a>
      );
    case "button":
      return <button className={classNames}>{props.children}</button>;
  }
};
