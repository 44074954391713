import React from "react";

import { Logo } from "./Logo";
import { Display } from "./Display";
import { Heading } from "./Heading";
import { Button } from "./Button";
import { ArrowRight } from "./Icon/Icon";

export const Hero = () => (
  <section className="hero">
    <nav className="hero__nav">
      <div className="hero__logo">
        <Logo fill="#EEFBFF" />
      </div>
    </nav>
    <div className="hero__inner">
      <div className="hero__display">
        <img
          className="hero-display__image"
          src={
            "https://aginic-images.s3-ap-southeast-2.amazonaws.com/Mychro/Disp_Left-Mock.png"
          }
          alt="monitor displaying mychro"
        />
      </div>
      <div className="hero__content">
        <div className="hero-content__heading">
          <Display size="giga" color="primary--inverse">
            An X-Ray for your DNA.
          </Display>
        </div>
        <div className="hero-content__subheading">
          <Heading flushTop size={2} color="neutral--1">
            A modern day genome browser.
          </Heading>
        </div>

        <div className="hero-content__cta">
          <Button
            element={"a"}
            type="outline"
            size="large"
            onClick={evt => {
              ga(
                "send",
                "event",
                "demo",
                "requested-demo",
                "Demo request",
                "demo requested"
              );
              window.open(
                "mailto:alan@clearskygenomics.com?subject=Mychro Demo",
                "_self"
              );
            }}
          >
            Book a demo <ArrowRight size="small" />
          </Button>
        </div>
      </div>
    </div>
  </section>
);
