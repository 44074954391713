import React from "react";
import { ArrowLeft } from "./ArrowLeft";
import { ArrowRight } from "./ArrowRight";
import { Bars } from "./Bars";
import { ChevronDown } from "./ChevronDown";
import { ChevronLeft } from "./ChevronLeft";
import { EllipsisV } from "./EllipsisV";
import { FileExport } from "./FileExport";
import { Minus } from "./Minus";
import { Plus } from "./Plus";
import { Search } from "./Search";
import { SearchMinus } from "./SearchMinus";
import { SearchPlus } from "./SearchPlus";
import { SignOut } from "./SignOut";
import { Sitemap } from "./Sitemap";
import { Times } from "./Times";
import { UserEdit } from "./UserEdit";
import { UserPlus } from "./UserPlus";

/* Icons - provided by fontawesome
 * Each icon is a separate react component, all exported from this file. Each
 * icon component takes the same IconProps, and should behave the same way wrt
 * styling (aside from aspect ratios). Each individual icon differs only in two
 * ways - its SVG viewBox, and the d= parameter of the SVG path. In creating a
 * new icon, copy the code from an old icon, and change these parameters. Both
 * viewbox and d= can be found from the web inspector on fontawesome.com.
 * inspector.
 */

export {
  ArrowLeft,
  ArrowRight,
  Bars,
  ChevronDown,
  ChevronLeft,
  EllipsisV,
  FileExport,
  Minus,
  Plus,
  Search,
  SearchMinus,
  SearchPlus,
  SignOut,
  Sitemap,
  Times,
  UserEdit,
  UserPlus
};

import "./Icon.scss";

export interface IconProps {
  // defaults to between large and small
  size?: "fill" | "x-large" | "large" | "small" | "x-small";
  // override the CSS class of the icon
  className?: string;
}
