import React from "react";
import { IconProps } from "./Icon";

export const FileExport = (props: IconProps) => {
  const className = [
    "icon",
    props.size && `icon--${props.size}`,
    props.className
  ]
    .join(" ")
    .trim();

  return (
    <svg className={className} viewBox="0 0 576 512">
      <path
        fill="currentColor"
        d="M572.29 279.06l-71.77-72.31c-7.55-7.6-20.52-2.26-20.52 8.45v48.88h-96v-132.1c0-12.7-5.17-25-14.17-33.99L285.94 14.1c-9-9-21.2-14.1-33.89-14.1H47.99C21.5.1 0 21.6 0 48.09v415.92C0 490.5 21.5 512 47.99 512h287.94c26.5 0 48.07-21.5 48.07-47.99V360.07h-48v103.94H47.99V48.09h159.97v103.98c0 13.3 10.7 23.99 24 23.99H336v88.01H172c-6.63 0-12 5.37-12 12v24c0 6.63 5.37 12 12 12h308v48.88c0 10.71 12.97 16.05 20.52 8.45l71.77-72.31c4.95-4.99 4.95-13.04 0-18.03zM255.95 128.07V51.99l76.09 76.08h-76.09z"
      />
    </svg>
  );
};
