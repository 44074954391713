import React from "react";

import { Hero } from "./Hero";

export const App = () => {
  return (
    // Navigation bar
    // Hero Section
    // Mission section
    // Testimonials
    // Features
    // Team
    // Footer
    <>
      {/* <header>&nbsp;</header> */}
      <main className="app">
        <Hero />
      </main>
      {/* <footer>&nbsp;</footer> */}
    </>
  );
};
