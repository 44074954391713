import React from "react";
import { IconProps } from "./Icon";

export const Minus = (props: IconProps) => {
  const className = [
    "icon",
    props.size && `icon--${props.size}`,
    props.className
  ]
    .join(" ")
    .trim();

  return (
    <svg className={className} viewBox="0 0 384 512">
      <path
        fill="currentColor"
        d="M368 224H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h352c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z"
      />
    </svg>
  );
};
